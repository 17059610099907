import React from "react";
import { ThemeProvider } from "@mui/material";
import type { AppProps } from "next/app";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import Script from "next/script";
import { theme } from "@/theme/theme";
import { persistor, store } from "@/redux/store";
import "./globals.css";

type AppPropsWithError = AppProps & {
  err?: Error;
};

function MyApp({ Component, pageProps, err }: AppPropsWithError) {
  function renderComponent() {
    if (err) {
      return <Component {...pageProps} err={err} />;
    } else {
      return (
        <ThemeProvider theme={theme}>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <Component {...pageProps} />
            </PersistGate>
          </Provider>
        </ThemeProvider>
      );
    }
  }

  return (
    <>
      <Script id="google-tag-manager" strategy="afterInteractive">
        {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-MZB2V6K');
      `}
      </Script>
      {renderComponent()}
    </>
  );
}

export default MyApp;
