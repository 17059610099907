export const Colors = {
  primary: "#00A73C",
  secondary: "#ECECEC",
  blue: "#006CA6",
  darkGrey: "#575757",
  black: "#000000",
  white: "#FFFFFF",
  backgroundGrey: "#E5E5E5",
  lightBackgroundGrey: "#C2C2C2",
  lightGrey: "#B0B0B0",
  darkGreen: "#163D23",
  textDarkGrey: "#414042",
  disabled: "#767676",
};
