import { createTheme } from "@mui/material";
import { Colors } from "@/theme/colors";

export const theme = createTheme({
  palette: {
    primary: { main: Colors.primary },
    secondary: { main: Colors.secondary, contrastText: Colors.black },
    success: { main: Colors.blue },
  },
  typography: {
    // see global.css for TruHearing-Rounded @font-face
    fontFamily: "TruHearing-Rounded, Arial",
  },
});
