import type { PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "@/redux/store";
import { Partner, SecondaryDisplayName } from "@/lib/api/partners";

export interface PartnerState {
  partner?: Partner;
  selectedSecondaryDisplayNameId?: number;
  providerFreeText?: string;
  partnerSelectionError: string;
  partnerBtnSubmitted: boolean;
}

export const partnerInitialState: PartnerState = {
  partnerSelectionError: "",
  partnerBtnSubmitted: false,
};

export const setPartnerSelectError = createAsyncThunk<void, string, { state: RootState; rejectWithValue: Error }>(
  "partner/partnerSelectError",
  async (selectionErrorMsg, { dispatch }) => {
    await dispatch(resetPartner());
    await dispatch(setPartnerSelectionError(selectionErrorMsg));
  }
);

export const partnerSlice = createSlice({
  name: "partner",
  initialState: partnerInitialState,
  reducers: {
    setPartner: (state, action: PayloadAction<Partner | undefined>) => {
      state.partnerSelectionError = "";
      state.partner = action.payload;
    },
    setSecondaryDisplayNameId: (state, action: PayloadAction<number | undefined>) => {
      state.selectedSecondaryDisplayNameId = action.payload;
    },
    setProviderFreeText: (state, action: PayloadAction<string | undefined>) => {
      state.providerFreeText = action.payload;
    },
    resetPartner: () => partnerInitialState,
    setPartnerSelectionError: (state, action: PayloadAction<string>) => {
      state.partnerSelectionError = action.payload;
    },
    setPartnerBtnSubmitted: state => {
      state.partnerBtnSubmitted = true;
    },
  },
});

export const getPartner = (state: RootState) => state.partner.partner;
export const getSecondaryDisplayNameId = (state: RootState) => state.partner.selectedSecondaryDisplayNameId;
export const getSecondaryDisplayName = (state: RootState) =>
  state.partner.selectedSecondaryDisplayNameId
    ? state.partner.partner?.secondaryDisplayNames?.find(
        (sdn: SecondaryDisplayName) => sdn.partnerId === state.partner.selectedSecondaryDisplayNameId
      )
    : undefined;
export const getProviderFreeText = (state: RootState) => state.partner.providerFreeText;
export const getPartnerSelectionError = (state: RootState) => state.partner.partnerSelectionError;

export const getPartnerBtnSubmitted = (state: RootState) => state.partner.partnerBtnSubmitted;

// Action creators are generated for each case reducer function
export const {
  setPartner,
  setSecondaryDisplayNameId,
  resetPartner,
  setProviderFreeText,
  setPartnerSelectionError,
  setPartnerBtnSubmitted,
} = partnerSlice.actions;

export const partnerReducer = partnerSlice.reducer;
