import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@/redux/store";

export interface UserState {
  redirect?: string;
  surveyId?: string;
  skippedQuestionnaire?: boolean;
}

export const userInitialState: UserState = {};

export const userSlice = createSlice({
  name: "user",
  initialState: userInitialState,
  reducers: {
    setRedirect: (state, action: PayloadAction<string | undefined>) => {
      state.redirect = action.payload;
    },
    setSurveyId: (state, action: PayloadAction<string | undefined>) => {
      state.surveyId = action.payload;
    },
    setSkippedQuestionnaire: (state, action: PayloadAction<boolean>) => {
      state.skippedQuestionnaire = action.payload;
    },
    resetUser: () => userInitialState,
  },
});

export const getRedirect = (state: RootState) => state.user.redirect;
export const getSurveyId = (state: RootState) => state.user.surveyId;
export const getSkippedQuestionnaire = (state: RootState) => state.user.skippedQuestionnaire;
export const getShouldShowQuestionnaire = (state: RootState) => !state.user.skippedQuestionnaire;

// Action creators are generated for each case reducer function
export const { setRedirect, setSurveyId, setSkippedQuestionnaire, resetUser } = userSlice.actions;

export const userReducer = userSlice.reducer;
