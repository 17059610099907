import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { Product } from "@/lib/api/products";
import { RootState } from "@/redux/store";
import { usd } from "@/lib/currency";

export interface CartItem {
  product: Product;
  quantity: number;
}

export interface CartState {
  items: CartItem[];
  subtotal: number;
  total: number;
}

export const cartInitialState: CartState = {
  items: [],
  subtotal: 0,
  total: 0,
};

export interface UpdateProductQuantityPayload {
  index: number;
  quantity: number;
}

const getSubtotal = (items: CartItem[]) => {
  let subtotal = usd(0);
  items.forEach(item => {
    subtotal = subtotal.add(usd(item.product.truhearingPrice).multiply(item.quantity));
  });
  return subtotal.value;
};
const getTotal = getSubtotal;

export const cartSlice = createSlice({
  name: "cart",
  initialState: cartInitialState,
  reducers: {
    addProduct: (state, action: PayloadAction<Product>) => {
      let found = false;
      state.items.forEach(item => {
        if (item.product.id === action.payload.id) {
          item.quantity += 1;
          found = true;
        }
      });
      if (!found) {
        const newCartItem: CartItem = {
          product: action.payload,
          quantity: 1,
        };
        state.items.push(newCartItem);
      }
      state.subtotal = getSubtotal(state.items);
      state.total = getTotal(state.items);
    },
    setProductQuantity: (state, action: PayloadAction<UpdateProductQuantityPayload>) => {
      if (action.payload.quantity <= 0) {
        state.items.splice(action.payload.index, 1);
      } else {
        state.items[action.payload.index].quantity = action.payload.quantity;
      }
      state.subtotal = getSubtotal(state.items);
      state.total = getTotal(state.items);
    },
    resetCart: () => cartInitialState,
  },
});

export const getCart = (state: RootState) => state.cart;

// Action creators are generated for each case reducer function
export const { addProduct, setProductQuantity, resetCart } = cartSlice.actions;

export const cartReducer = cartSlice.reducer;
